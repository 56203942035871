import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

export default function Thanks() {
  const [secondsLeft, setSecondsLeft] = useState(6)
  useEffect(() => {
    setTimeout(() => setSecondsLeft(secondsLeft - 1), 1000)
  }, [secondsLeft])

  if (secondsLeft === 0) {
    navigate("/")
  }
  return (
    <div className="bg-gray-100 dark:bg-gray-900 w-screen h-screen flex justify-center items-center flex-col p-8">
      <span className="font-sans font-bold text-4xl md:text-6xl inline-block text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-black dark:to-white uppercase">
        Thanks for your submission!
      </span>
      <span className="font-sans text-2xl text-gray-900 dark:text-gray-300">
        Your message has been received, you will be sent back to the main page
        in {secondsLeft} seconds
      </span>
    </div>
  )
}
